import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _typeof from "@babel/runtime/helpers/typeof";
/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: eprivacy
 * Version: 7.1.1
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function format(string) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  var t = _typeof(args[0]);
  var values;
  if (args.length == 0) values = {};else values = 'string' === t || 'number' === t ? args : args[0];
  var splits = [];
  var s = string;
  while (s.length > 0) {
    var m = s.match(/\{(?!\{)([\w\d]+)\}(?!\})/);
    if (m !== null) {
      var left = s.substr(0, m.index);
      var sep = s.substr(m.index, m[0].length);
      s = s.substr(m.index + m[0].length);
      var n = parseInt(m[1]);
      splits.push(left);
      if (n != n) {
        // not a number
        splits.push(values[m[1]]);
      } else {
        // a numbered argument
        splits.push(values[n]);
      }
    } else {
      splits.push(s);
      s = '';
    }
  }
  return splits;
}
export function language() {
  return window.language || document.documentElement.lang || 'en';
}
function hget(d, key, defaultValue) {
  var kl = key;
  if (!Array.isArray(kl)) kl = [kl];
  var cv = d;
  for (var i = 0; i < kl.length; i++) {
    if (cv === undefined) return defaultValue;
    if (cv instanceof Map) cv = cv.get(kl[i]);else cv = cv[kl[i]];
  }
  if (cv === undefined) return defaultValue;
  return cv;
}
export function t(trans, lang, debug, key) {
  var kl = key;
  if (!Array.isArray(kl)) kl = [kl];
  var value = hget(trans, [lang].concat(_toConsumableArray(kl)));
  if (value === undefined) {
    if (debug) {
      console.log(format('[missing translation: {lang}/{key}]', {
        key: kl.join('/'),
        lang: lang
      }).join(''));
    }
    return false;
  }
  var params = Array.prototype.slice.call(arguments, 4);
  if (params.length > 0) return format.apply(void 0, [value].concat(_toConsumableArray(params)));
  return value;
}
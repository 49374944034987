/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: eprivacy
 * Version: 7.1.1
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
export default function useLegacyLifecycleMethods() {
  var reactVersion = React.version.match(/(\d+)\.(\d+)*/);
  var major = reactVersion[1] * 1;
  var minor = reactVersion[2] * 1;
  return major <= 15 || major === 16 && minor <= 2;
}
/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: eprivacy
 * Version: 7.1.1
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
export function getCookies() {
  var cookieStrings = document.cookie.split(';');
  var cookies = [];
  var regex = new RegExp('^\\s*([^=]+)\\s*=\\s*(.*?)$');
  for (var i = 0; i < cookieStrings.length; i++) {
    var cookieStr = cookieStrings[i];
    var match = regex.exec(cookieStr);
    if (match === null) continue;
    cookies.push({
      name: match[1],
      value: match[2]
    });
  }
  return cookies;
}
export function getCookie(name) {
  var cookies = getCookies();
  for (var i = 0; i < cookies.length; i++) {
    if (cookies[i].name == name) return cookies[i];
  }
  return null;
}
function pair(key, value) {
  return "".concat(key, "=").concat(value);
}

//https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
export function setCookie(name) {
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var days = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var domain = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
  var cookie = [pair(name, value)];
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    cookie.push(pair('expires', date.toUTCString()));
  }
  if (domain) {
    cookie.push(pair('domain', domain));
  }
  cookie.push(pair('path', '/'));
  document.cookie = cookie.join('; ');
}
export function deleteCookie(name, path, domain) {
  var cookieString = "".concat(name, "=; Max-Age=-99999999;").concat(path !== undefined ? " path=".concat(path, ";") : " path=/;");
  if (domain !== undefined) {
    document.cookie = "".concat(cookieString, " domain=").concat(domain, ";");
    return;
  }
  // if domain is not defined, try to delete cookie on multiple default domains
  document.cookie = cookieString;
  document.cookie = "".concat(cookieString, " domain=.").concat(location.hostname, ";");
  // handle subdomains
  document.cookie = "".concat(cookieString, " domain=.").concat(location.hostname.split('.').slice(-2).join('.'), ";");
}
/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: eprivacy
 * Version: 7.1.1
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// To create a new translation, add a YAML file with the required entries and
// link it here using the appropriate language code.
var ca = {
  consentModal: {
    title: "La informaci\xF3 que recollim",
    description: "Aqu\xED podeu veure i personalitzar la informaci\xF3 que recopilem sobre vosaltres.\n",
    privacyPolicy: {
      name: "pol\xEDtica de privacitat",
      text: "Per saber-ne m\xE9s, llegiu la nostre {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "S'han produ\xEFt canvis des de la vostra \xFAltima visita, actualitzeu el vostre consentiment.",
    description: "Recopilem i processem la vostra informaci\xF3 personal amb la finalitat seg\xFCent : {purposes}.\n",
    learnMore: "M\xE9s informaci\xF3"
  },
  accept: "Acceptar",
  acceptTitle: "Acceptar les cookies",
  acceptAll: "Acceptar-ho tot",
  save: "Desa",
  saveData: "Desa la meva configuraci\xF3 sobre la informaci\xF3 recollida",
  decline: "Refusar",
  declineAll: "Rebutja tot",
  close: "Tancar",
  enabled: "Actiu",
  disabled: "Inactiu",
  app: {
    optOut: {
      title: "(desactivar)",
      description: "Aquesta aplicaci\xF3 es carrega de manera predeterminada (per\xF2 podeu desactivar-la)"
    },
    required: {
      title: "(sempre obligatori)",
      description: "Aquesta aplicaci\xF3 sempre \xE9s necess\xE0ria"
    },
    purposes: "Usos",
    purpose: "\xDAs"
  },
  poweredBy: "Desenvolupat per Orejime",
  newWindow: "finestra nova"
};
var de = {
  consentModal: {
    title: "Informationen zur Privatsph\xE4re",
    description: "Hier k\xF6nnen Sie einsehen und anpassen, welche pers\xF6nlichen Daten gespeichert werden.\n",
    privacyPolicy: {
      name: "Datenschutzerkl\xE4rung",
      text: "Bitte lesen Sie unsere {privacyPolicy} um weitere Details zu erfahren.\n"
    }
  },
  consentNotice: {
    changeDescription: "Es gab \xC4nderungen seit Ihrem letzten Besuch, bitte aktualisieren Sie Ihre Auswahl.",
    description: "Wir speichern und verarbeiten Ihre pers\xF6nlichen Daten f\xFCr folgende Zwecke: {purposes}.\n",
    learnMore: "Mehr erfahren"
  },
  accept: "Akzeptieren",
  acceptTitle: "Cookies akzeptieren",
  acceptAll: "Alle Einstellungen akzeptieren",
  save: "Speichern",
  saveData: "Meine Einstellungen speichern",
  decline: "Ablehnen",
  declineAll: "Alle Einstellungen ablehnen",
  close: "Schlie\xDFen",
  enabled: "Aktiviert",
  disabled: "Deaktiviert",
  app: {
    optOut: {
      title: "(Opt-Out)",
      description: "Diese Einstellungen als Standard festlegen (sie k\xF6nnen jederzeit deaktiviert werden)"
    },
    required: {
      title: "(immer notwendig)",
      description: "Diese Einstellungen werden immer ben\xF6tigt"
    },
    purposes: "Zwecke",
    purpose: "Zweck"
  },
  poweredBy: "Durchgef\xFChrt mit Orejime",
  newWindow: "Neues Fenster"
};
var en = {
  consentModal: {
    title: "Information that we collect",
    description: "Here you can see and customize the information that we collect about you.\n",
    privacyPolicy: {
      name: "privacy policy",
      text: "To learn more, please read our {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "There were changes since your last visit, please update your consent.",
    description: "We collect and process your personal information for the following purposes: {purposes}.\n",
    learnMore: "Learn more"
  },
  accept: "Accept",
  acceptTitle: "Accept cookies",
  acceptAll: "Accept all apps",
  save: "Save",
  saveData: "Save my configuration on collected information",
  decline: "Decline",
  declineAll: "Decline all apps",
  close: "Close",
  enabled: "Enabled",
  disabled: "Disabled",
  app: {
    optOut: {
      title: "(opt-out)",
      description: "This app is loaded by default (but you can opt out)"
    },
    required: {
      title: "(always required)",
      description: "This application is always required"
    },
    purposes: "Purposes",
    purpose: "Purpose"
  },
  poweredBy: "Powered by Orejime",
  newWindow: "new window"
};
var es = {
  consentModal: {
    title: "La informaci\xF3n que recopilamos",
    description: "Aqu\xED puede ver y personalizar la informaci\xF3n que recopilamos sobre usted.\n",
    privacyPolicy: {
      name: "pol\xEDtica de confidencialidad",
      text: "Para obtener m\xE1s informaci\xF3n, lea nuestra {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "Se han producido cambios desde su \xFAltima visita, actualice su consentimiento.",
    description: "Recopilamos y procesamos su informaci\xF3n personal para los siguientes prop\xF3sitos : {purposes}.\n",
    learnMore: "M\xE1s informaci\xF3n"
  },
  accept: "Aceptar",
  acceptTitle: "Aceptar cookies",
  acceptAll: "Aceptar todas las apps",
  save: "Guardar",
  saveData: "Guardar mi configuraci\xF3n en la informaci\xF3n recopilada",
  decline: "Rechazar",
  declineAll: "Rechazar todas las apps",
  close: "Cerrar",
  enabled: "Habilitado",
  disabled: "Deshabilitado",
  app: {
    optOut: {
      title: "(desactivar)",
      description: "Esta aplicaci\xF3n se carga por defecto (pero puedes desactivarla)"
    },
    required: {
      title: "(siempre obligatorio)",
      description: "Esta aplicaci\xF3n es siempre obligatoria"
    },
    purposes: "Prop\xF3sitos",
    purpose: "Prop\xF3sito"
  },
  poweredBy: "Desarrollado por Orejime",
  newWindow: "nueva ventana"
};
var et = {
  consentModal: {
    title: "Isikuandmete kogumine",
    description: "Siit saate vaadata ja hallata teavet, mida me teie kohta kogume.\n",
    privacyPolicy: {
      name: "privaatsustingimused",
      text: "Lisateabe saamiseks lugege palun meie {privacyPolicy}\n"
    }
  },
  consentNotice: {
    changeDescription: "P\xE4rast teie viimast k\xFClastust on toimunud muudatusi, palun uuendage oma n\xF5usolekut.",
    description: "Me kogume ja t\xF6\xF6tleme teie isikuandmeid j\xE4rgmistel eesm\xE4rkidel: {purposes}.\n",
    learnMore: "Lisateave"
  },
  accept: "N\xF5ustu",
  acceptTitle: "N\xF5ustu k\xFCpsistega",
  acceptAll: "N\xF5ustu k\xF5igi rakendustega",
  save: "Salvesta",
  saveData: "Salvesta kogutud teabe seadistused",
  decline: "Keeldu",
  declineAll: "Keela k\xF5ik rakendused",
  close: "Sulge",
  enabled: "Lubatud",
  disabled: "V\xE4lja l\xFClitatud",
  app: {
    optOut: {
      title: "(Opt-Out)",
      description: "See rakendus on vaikimisi lisatud (kuid saate sellest loobuda)"
    },
    required: {
      title: "(alati vajalik)",
      description: "See rakendus on alati vajalik"
    },
    purposes: "Eesm\xE4rgid",
    purpose: "Eesm\xE4rk"
  },
  poweredBy: "Teenuse pakkuja on Orejime",
  newWindow: "uus aken"
};
var fi = {
  consentModal: {
    title: "Ker\xE4\xE4m\xE4mme tiedot",
    description: "Voit tarkastella ja muokata sinusta ker\xE4\xE4mi\xE4mme tietoja.\n",
    privacyPolicy: {
      name: "tietosuojasivultamme",
      text: "Voit lukea lis\xE4tietoja {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "Olemme tehneet muutoksia ehtoihin viime vierailusi j\xE4lkeen, tarkista ehdot.",
    description: "Ker\xE4\xE4mme ja k\xE4sittelemme henkil\xF6tietoja seuraaviin tarkoituksiin: {purposes}.\n",
    learnMore: "Lue lis\xE4\xE4"
  },
  save: "Tallenna",
  decline: "Hylk\xE4\xE4",
  app: {
    optOut: {
      title: "(ladataan oletuksena)",
      description: "Ladataan oletuksena (mutta voit ottaa sen pois p\xE4\xE4lt\xE4)"
    },
    required: {
      title: "(vaaditaan)",
      description: "Sivusto vaatii t\xE4m\xE4n aina"
    },
    purposes: "K\xE4ytt\xF6tarkoitukset",
    purpose: "K\xE4ytt\xF6tarkoitus"
  },
  poweredBy: "Palvelun tarjoaa Orejime"
};
var fr = {
  consentModal: {
    title: "Les informations que nous collectons",
    description: "Ici, vous pouvez voir et personnaliser les informations que nous collectons sur vous.\n",
    privacyPolicy: {
      name: "politique de confidentialit\xE9",
      text: "Pour en savoir plus, merci de lire notre {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "Des modifications ont eu lieu depuis votre derni\xE8re visite, merci de mettre \xE0 jour votre consentement.",
    description: "Nous collectons et traitons vos informations personnelles dans le but suivant : {purposes}.\n",
    learnMore: "En savoir plus"
  },
  accept: "Accepter",
  acceptTitle: "Accepter les cookies",
  acceptAll: "Tout accepter",
  save: "Sauvegarder",
  saveData: "Sauvegarder ma configuration sur les informations collect\xE9es",
  decline: "Refuser",
  declineAll: "Tout refuser",
  close: "Fermer",
  enabled: "Activ\xE9",
  disabled: "D\xE9sactiv\xE9",
  app: {
    optOut: {
      title: "(opt-out)",
      description: "Cette application est charg\xE9e par d\xE9faut (mais vous pouvez la d\xE9sactiver)"
    },
    required: {
      title: "(toujours requis)",
      description: "Cette application est toujours requise"
    },
    purposes: "Utilisations",
    purpose: "Utilisation"
  },
  poweredBy: "Propuls\xE9 par Orejime",
  newWindow: "nouvelle fen\xEAtre"
};
var hu = {
  consentModal: {
    title: "Inform\xE1ci\xF3k, amiket gy\u0171jt\xFCnk",
    description: "Itt l\xE1thatod \xE9s testreszabhatod az r\xF3lad gy\u0171jt\xF6tt inform\xE1ci\xF3kat.\n",
    privacyPolicy: {
      name: "adatv\xE9delmi ir\xE1nyelveinket",
      text: "Tov\xE1bbi inform\xE1ci\xF3\xE9rt k\xE9rj\xFCk, olvassd el az {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "Az utols\xF3 l\xE1togat\xE1s \xF3ta v\xE1ltoz\xE1sok t\xF6rt\xE9ntek, k\xE9rj\xFCk, friss\xEDtsd a hozz\xE1j\xE1rul\xE1sodat.",
    description: "Az szem\xE9lyes adataidat \xF6sszegy\u0171jtj\xFCk \xE9s feldolgozzuk az al\xE1bbi c\xE9lokra: {purposes}.\n",
    learnMore: "Tudj meg t\xF6bbet"
  },
  save: "Ment\xE9s",
  decline: "Elvet",
  app: {
    optOut: {
      title: "(leiratkoz\xE1s)",
      description: "Ez az alkalmaz\xE1s alap\xE9rtelmez\xE9s szerint bet\xF6lt\xF6tt (de ki lehet kapcsolni)"
    },
    required: {
      title: "(mindig k\xF6telez\u0151)",
      description: "Ez az applik\xE1ci\xF3 mindig k\xF6telez\u0151"
    },
    purposes: "C\xE9lok",
    purpose: "C\xE9l"
  },
  poweredBy: "Powered by Orejime"
};
var it = {
  consentModal: {
    title: "Informazioni che raccogliamo",
    description: "Qui puoi vedere e scegliere le informazioni che raccogliamo su di te.\n",
    privacyPolicy: {
      name: "policy privacy",
      text: "Per saperne di pi\xF9, leggi la nostra {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "Ci sono stati cambiamenti dalla tua ultima visita, aggiorna il tuo consenso.",
    description: "Raccogliamo ed elaboriamo le vostre informazioni personali per i seguenti scopi: {purposes}.\n",
    learnMore: "Scopri di pi\xF9"
  },
  save: "Salva",
  decline: "Rifiuta",
  app: {
    optOut: {
      title: "(opt-out)",
      description: "Quest'applicazione \xE8 caricata di default (ma puoi disattivarla)"
    },
    required: {
      title: "(sempre richiesto)",
      description: "Quest'applicazione \xE8 sempre richiesta"
    },
    purposes: "Scopi",
    purpose: "Scopo"
  },
  poweredBy: "Realizzato da Orejime"
};
var nb = {
  consentModal: {
    title: "Informasjon vi samler inn",
    description: "Her kan du se og velge hvilken informasjon vi samler inn om deg.\n",
    privacyPolicy: {
      name: "personvernerkl\xE6ring",
      text: "For \xE5 l\xE6re mer, vennligst les v\xE5r {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "Det har skjedd endringer siden ditt siste bes\xF8k, vennligst oppdater ditt samtykke.",
    description: "Vi samler inn og prosesserer din personlige informasjon av f\xF8lgende \xE5rsaker: {purposes}.\n",
    learnMore: "L\xE6r mer"
  },
  save: "Opslaan",
  decline: "Avsl\xE5",
  app: {
    optOut: {
      title: "(opt-out)",
      description: "Denne appen er lastet som standard (men du kan skru det av)"
    },
    required: {
      title: "(alltid p\xE5krevd)",
      description: "Denne applikasjonen er alltid p\xE5krevd"
    },
    purposes: "\xC5rsaker",
    purpose: "\xC5rsak"
  },
  poweredBy: "Laget med Orejime"
};
var nl = {
  consentModal: {
    title: "Informatie die we verzamelen",
    description: "Hier kunt u de informatie bekijken en aanpassen die we over u verzamelen.\n",
    privacyPolicy: {
      name: "privacybeleid",
      text: "Lees ons privacybeleid voor meer informatie {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "Er waren wijzigingen sinds uw laatste bezoek, werk uw voorkeuren bij.",
    description: "Wij verzamelen en verwerken uw persoonlijke gegevens voor de volgende doeleinden: {purposes}.\n",
    learnMore: "Lees meer"
  },
  accept: "Aanvaarden",
  acceptAll: "Alles aanvaarden",
  save: "Opslaan",
  saveData: "Mijn configuratie voor de informatievergaring opslaan",
  decline: "Afwijzen",
  declineAll: "Alles weigeren",
  close: "Sluiten",
  enabled: "Geactiveerd",
  disabled: "Gedesactiveerd",
  app: {
    optOut: {
      title: "(afmelden)",
      description: "Deze app is standaard geladen (maar je kunt je afmelden)"
    },
    required: {
      title: "(altijd verplicht)",
      description: "Deze applicatie is altijd vereist"
    },
    purposes: "Doeleinden",
    purpose: "Doeleinde"
  },
  poweredBy: "Aangedreven door Orejime",
  newWindow: "nieuw venster"
};
var oc = {
  consentModal: {
    title: "Las informacions que reculhiss\xE8m",
    description: "Aic\xED, pod\xE8tz veire e personalizar las informacions que reculhiss\xE8m vos tocant.\n",
    privacyPolicy: {
      name: "politica de confidencialitat",
      text: "Per ne saber mai, merc\xE9s de legir n\xF2stra {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "I agu\xE8t de modificacions dempu\xE8i v\xF2stra darri\xE8ra visita, merc\xE9s d\u2019actualizar v\xF2stre consentiment.",
    description: "Reculhiss\xE8m e tractam v\xF2stras informacions personalas amb la t\xF2ca seguenta : {purposes}.\n",
    learnMore: "Ne saber mai"
  },
  accept: "Acceptar",
  acceptTitle: "Acceptar los cookies",
  acceptAll: "Tot acceptar",
  save: "Salvagardar",
  saveData: "Salvagardar ma configuracion per las informacions collectadas",
  decline: "Refusar",
  declineAll: "Tot refusar",
  close: "Tancar",
  enabled: "Activat",
  disabled: "Desactivat",
  app: {
    optOut: {
      title: "(opt-out)",
      description: "Aquesta aplicacion es cargada per defaut (mas la pod\xE8tz desactivar)"
    },
    required: {
      title: "(tojors requerit)",
      description: "Aquesta aplicacion es totjorn requerida"
    },
    purposes: "Utilizacions",
    purpose: "Utilizacion"
  },
  poweredBy: "Propulsat per Orejime",
  newWindow: "fen\xE8stra nov\xE8la"
};
var ro = {
  consentModal: {
    title: "Informa\u021Biile pe care le colect\u0103m",
    description: "Aici pute\u021Bi vedea \u0219i personaliza informa\u021Biile pe care le colect\u0103m despre dvs.\n",
    privacyPolicy: {
      name: "politica privacy",
      text: "Pentru a afla mai multe, v\u0103 rug\u0103m s\u0103 citi\u021Bi {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "Au existat modific\u0103ri de la ultima vizit\u0103, v\u0103 rug\u0103m s\u0103 actualiza\u021Bi consim\u021B\u0103m\xE2ntul.",
    description: "Colect\u0103m \u0219i proces\u0103m informa\u021Biile dvs. personale \xEEn urm\u0103toarele scopuri: {purposes}.\n",
    learnMore: "Afl\u0103 mai multe"
  },
  save: "Salveaz\u0103",
  decline: "Renun\u021B\u0103",
  app: {
    optOut: {
      title: "(opt-out)",
      description: "Aceast\u0103 aplica\u021Bie este \xEEnc\u0103rcat\u0103 \xEEn mod implicit (dar pute\u021Bi renun\u021Ba)"
    },
    required: {
      title: "(\xEEntotdeauna necesar)",
      description: "Aceast\u0103 aplica\u021Bie este \xEEntotdeauna necesar\u0103"
    },
    purposes: "Scopuri",
    purpose: "Scop"
  },
  poweredBy: "Realizat de Orejime"
};
var sv = {
  consentModal: {
    title: "Information som vi samlar",
    description: "H\xE4r kan du se och anpassa vilken information vi samlar om dig.\n",
    privacyPolicy: {
      name: "Integritetspolicy",
      text: "F\xF6r att veta mer, l\xE4s v\xE5r {privacyPolicy}.\n"
    }
  },
  consentNotice: {
    changeDescription: "Det har skett f\xF6r\xE4ndringar sedan ditt senaste bes\xF6k, var god uppdatera ditt medgivande.",
    description: "Vi samlar och bearbetar din personliga data i f\xF6ljande syften: {purposes}.\n",
    learnMore: "L\xE4s mer"
  },
  save: "Spara",
  decline: "Avb\xF6j",
  app: {
    optOut: {
      title: "(Avaktivera)",
      description: "Den h\xE4r appen laddas som standardinst\xE4llning (men du kan avaktivera den)"
    },
    required: {
      title: "(Kr\xE4vs alltid)",
      description: "Den h\xE4r applikationen kr\xE4vs alltid"
    },
    purposes: "Syften",
    purpose: "Syfte"
  },
  poweredBy: "K\xF6rs p\xE5 Orejime"
};
export default {
  ca: ca,
  de: de,
  en: en,
  es: es,
  et: et,
  fi: fi,
  fr: fr,
  hu: hu,
  it: it,
  nb: nb,
  nl: nl,
  oc: oc,
  ro: ro,
  sv: sv
};
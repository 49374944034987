/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: eprivacy
 * Version: 7.1.1
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import * as Orejime from 'orejime';

import(/*webpackIgnore: true*/'/eprivacy/orejime/configuration.js').then((orejimeConfigDynamicModule) => {
  const orejimeConfigDynamic = orejimeConfigDynamicModule.default;
  if (typeof orejimeConfigDynamic !== 'undefined') {
    const orejime = Orejime.init(orejimeConfigDynamic);
    /* Au clic sur le bouton, on affiche la boite de dialogue de consentement */
    let buttonsConsent = document.querySelectorAll('.eprivacy-consent-modal-button');
    buttonsConsent.forEach(button =>
      button.addEventListener('click', function () {
        if (orejime) {
          orejime.show();
          eventModalOpened(orejime);
        }
      }, false)
    );
    /* Afficher/Cacher les blocs de descriptions de la pop in */
    let learnMoreButton = document.querySelector('.orejime-Notice-learnMoreButton');
    if (learnMoreButton) {
      learnMoreButton.addEventListener('click', () => eventModalOpened(orejime), false);
    }
  }
});

/**
 * Fonction déclenchée quand la modale est ouverte.
 * @param {Object} modal la modale orejime
 * @param {Number} tries le nombre d'essais
 */
function eventModalOpened(modal, tries = 0) {
  const allArrows = document.querySelectorAll('.orejime-AppItem-arrow');
  const allDetailsCat = document.querySelectorAll('.orejime-CategorizedAppList-item-social .orejime-CategorizedAppList-header .orejime-CategorizedAppList-details');

  if (allArrows.length === 0 && allDetailsCat.length === 0 && tries < 15) {
    setTimeout(() => eventModalOpened(modal, tries+1), 300);
    return;
  }

  /* Gestion du plier/déplier sur les flèches à côté des applications */
  for (const element of allArrows) {
    element.onclick = function () {
      this.parentNode.classList.toggle('active');
    };
  }
  /* Gestion du plier/déplier sur le "détail" à côté de la catégorie social */
  for (const element of allDetailsCat) {
    element.onclick = function () {
      this.parentNode.classList.toggle('activesocial');
    };
  }
}
